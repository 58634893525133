// apiConfig.js

//const FRONT_ENDPOINT = "http://192.168.100.58:3000";
const FRONT_ENDPOINT = "https://mymeme.com.br";
//const API_ENDPOINT = 'http://192.168.100.58:8080/api';
const API_ENDPOINT = 'https://mymeme.com.br/api';

export const endpoints = {
  MEMES: `${API_ENDPOINT}/memes`,
  MEMES_LIKE: `${API_ENDPOINT}/memes/like`,
  MEMES_APROVAL: `${API_ENDPOINT}/memes/aprovar`,
  VER_MEME: `${FRONT_ENDPOINT}/ver`
};

import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import Timeline from './Timeline';
import Header from './Header';
import logo from './logo_memes.png';
function App() {

  return (
    <div className="App">
      <Header />
      <main>
        <Timeline />
      </main>
    </div>
  );
}

export default App;

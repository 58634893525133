import React, { useState, useEffect } from 'react';
import './Cadastro.css'; // Você pode criar um arquivo CSS separado para estilização
import Header from './Header';
import { endpoints } from './apiConfig';

function Cadastro() {
  const [meme, setMeme] = useState({
    url: '',
    titulo: '',
    kibadoDe: '',
    autor: '',
    tokenRecaptcha: ''
  });

  const [errors, setErrors] = useState({
    url: '',
    titulo: '',
    kibadoDe: '',
    autor: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      return () => {
        // Remove o script quando o componente é desmontado
        document.head.removeChild(script);
      };
    }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMeme({
      ...meme,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação básica no frontend
    let newErrors = {};
    if (!meme.url) newErrors.url = 'Informar URL';
    if (!meme.titulo) newErrors.titulo = 'Informar titulo';
    if (!meme.autor) newErrors.autor = 'Informar o nome do autor';

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const recaptchaToken = window.grecaptcha.getResponse();
    if (!recaptchaToken) {
      alert('Por favor, complete o reCAPTCHA.');
      return;
    }

    setLoading(true);

    try {
      meme.tokenRecaptcha = recaptchaToken;
      const response = await fetch(endpoints.MEMES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(meme),
      });

      if (response.ok) {
        window.grecaptcha.reset();
        setMeme({ url: '', titulo: '', kibadoDe: '', autor: '' });
        setSuccessMessage('Meme salvo com sucesso!');

        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        alert('Erro ao cadastrar meme.');
      }
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao cadastrar meme.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <Header />
      <main>
        <h1>Cadastro de Meme</h1>
        {successMessage && <div className="success-message">{successMessage}</div>}
        <form onSubmit={handleSubmit} className="meme-form">
          <div className="form-group">
            <label htmlFor="titulo">Título*</label>
            <input
              type="text"
              id="titulo"
              name="titulo"
              value={meme.titulo}
              onChange={handleChange}
              className={errors.titulo ? 'error' : ''}
            />
            {errors.titulo && <span className="error-message">{errors.titulo}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="url">URL*</label>
            <input
              type="text"
              id="url"
              name="url"
              value={meme.url}
              onChange={handleChange}
              className={errors.url ? 'error' : ''}
            />
            {errors.url && <span className="error-message">{errors.url}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="kibadoDe">Kibado De</label>
            <input
              type="text"
              id="kibadoDe"
              name="kibadoDe"
              value={meme.kibadoDe}
              onChange={handleChange}
              className={errors.kibadoDe ? 'error' : ''}
            />
            {errors.kibadoDe && <span className="error-message">{errors.kibadoDe}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="autor">Autor*</label>
            <input
              type="text"
              id="autor"
              name="autor"
              value={meme.autor}
              onChange={handleChange}
              className={errors.autor ? 'error' : ''}
            />
            {errors.autor && <span className="error-message">{errors.autor}</span>}
          </div>
          <div className="g-recaptcha" data-sitekey="6Lc-DfIpAAAAAKp6eFaUFB2ATRtwy-tf-6oGfMtU" ></div>
          <br />
          <button type="submit" disabled={loading}>
            {loading ? 'Publicando...' : 'Publicar Meme'}
          </button>
        </form>
      </main>
    </div>
  );
}

export default Cadastro;

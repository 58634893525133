import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { endpoints } from './apiConfig';
import { format, parseISO } from 'date-fns';
import Header from './Header';
import positivo from './positivo.png';
import whatsappIcon from './whatsapp.png';
import './MemeDetails.css';

const MemeDetails = () => {
    const { uuid } = useParams();
    const [meme, setMeme] = useState(null);

    useEffect(() => {
        fetch(endpoints.MEMES + `/${uuid}`)
            .then(response => response.json())
            .then(data => setMeme(data))
            .catch(error => console.error('Erro ao buscar meme:', error));
    }, [uuid]);

    if (!meme) {
        return <p>Carregando...</p>;
    }

    const handleLike = (imageId) => {

        fetch(endpoints.MEMES_LIKE+`/${imageId}`, {
            method: 'POST',
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setMeme(meme.map(img => img.uuid === imageId ? { ...img, likes: data } : img));
                });
            } else {
                console.error('Erro ao adicionar like:', response.status);
            }
        })
        .catch(error => {
            console.error('Erro ao adicionar like:', error);
        });

    };

    const shareOnWhatsApp = () => {
        const url = `https://wa.me/?text=${encodeURIComponent(`Veja este meme: ${endpoints.VER_MEME}/${meme.uuid}`)}`;
        window.open(url, '_blank');
    };

    return (
        <div className="meme">
            <Helmet>
                <title>{meme.titulo}</title>
                <meta property="og:title" content={'Mymeme um lugar livre para os memes'} />
                <meta property="og:description" content={meme.titulo || 'Confira este meme incrível!'} />
                <meta property="og:image" content={meme.url} />
                <meta property="og:url" content={`${endpoints.VER_MEME}/${meme.uuid}`} />
            </Helmet>
            <Header />
            <div className="meme-details">
                <div className="timeline-item">
                    <div className="image-container">
                        <div className="header">
                            <p className="titulo">{meme.titulo}</p>
                            <p className="autor">{meme.autor}</p>
                        </div>
                        <img src={meme.url} alt={meme.alt} />
                    </div>
                    <div className="like-container">
                        <div className="cadastro_data">
                            {format(parseISO(meme.cadastro), 'dd/MM/yyyy HH:mm')}
                        </div>
                        <div className="do_like" onClick={() => handleLike(meme.uuid)}>
                            <img src={positivo} alt="Like" />
                        </div>
                        <div className="share-on-whatsapp" onClick={shareOnWhatsApp}>
                            <img src={whatsappIcon} alt="Compartilhar no WhatsApp" />
                        </div>
                        <div className="likes">
                            <p>Likes: {meme.likes}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemeDetails;

// Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import './Header.css';
import logo from './logo_memes.png';

function Header() {
  return (
    <header className="App-header">
      <Link to="/">
        <img src={logo} alt="Memes" className="App-logo" />
      </Link>
      <h1>My Meme</h1>
      <nav className="Header-nav">
        <Link to="/cadastro" className="Header-link">Publicar Meme</Link>
      </nav>
    </header>
  );
}

export default Header;

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Aprovacao.css';
import positivo from './positivo.png';
import { endpoints } from './apiConfig';
import { format, parseISO } from 'date-fns';
import debounce from 'lodash.debounce';
import Header from './Header';

const Timeline = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const pageRef = useRef(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            loadImages(2);
        } else {
            const debouncedHandleScroll = debounce(handleScroll, 200);
            window.addEventListener('scroll', debouncedHandleScroll);
            return () => {
                window.removeEventListener('scroll', debouncedHandleScroll);
            };
        }
    }, [initialized]);

    const loadImages = (size) => {
        setLoading(true);

        fetch(endpoints.MEMES+`?page=${pageRef.current}&size=${size}&status=PARA_VERIFICACAO`)
            .then(response => response.json())
            .then(data => {
                setImages(prevImages => [...prevImages, ...data]);
                setLoading(false);
                if(data.length > 0)
                    pageRef.current = pageRef.current + size;
            })
            .catch(error => {
                console.error('Erro ao buscar imagens:', error);
                setLoading(false);
            });
    };

    const handleScroll = () => {
        let tamanhoDaPagina = document.documentElement.offsetHeight;
        let dezPorcento = tamanhoDaPagina / 10;

        if (window.innerHeight + document.documentElement.scrollTop + dezPorcento >= tamanhoDaPagina) {
            loadImages(1);
        }

    };

    const handleLike = (imageId) => {

        const KEY = getCookie('CHAVE_APROVACAO');

        if (!KEY || KEY.trim() === '') {
            alert('Informar chave de envio!');
            return;
        }

        fetch(endpoints.MEMES_APROVAL+`/${imageId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ like: true, key: KEY })
        })
        .then(response => {
            if (response.ok) {
                setImages(images.filter(img => img.uuid !== imageId));
            } else {
                console.error('Erro ao adicionar like:', response.status);
            }
        })
        .catch(error => {
            console.error('Erro ao adicionar like:', error);
        });

    };

    function getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    return (
        <div>
          <Header />
          <main>
            <div className="timeline">
                        {images.map(image => (
                            <div key={image.id} className="timeline-item" >
                                <div className="image-container">
                                    <div className="header">
                                        <p className="titulo">{image.titulo}</p>
                                        <p className="autor">{image.autor}</p>
                                    </div>
                                    <img src={image.url} alt={image.alt} />
                                </div>
                                <div className="like-container">
                                    <div className="cadastro_data">
                                        {format(parseISO(image.cadastro), 'dd/MM/yyyy HH:mm')}
                                    </div>
                                    <div className="do_like" onClick={() => handleLike(image.uuid)}>
                                        <img src={positivo} alt="Like" />
                                    </div>
                                    <div className="likes">
                                        <p>Likes: {image.likes}</p>
                                    </div>
                                </div>
                            </div>

                        ))}
                        {loading && <p>Carregando...</p>}
                    </div>
          </main>
        </div>
    );
};

export default Timeline;

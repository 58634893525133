import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Timeline.css';
import positivo from './positivo.png';
import { endpoints } from './apiConfig';
import { format, parseISO } from 'date-fns';
import debounce from 'lodash.debounce';

const Timeline = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const pageRef = useRef(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            loadImages(2);
        } else {
            const debouncedHandleScroll = debounce(handleScroll, 200);
            window.addEventListener('scroll', debouncedHandleScroll);
            return () => {
                window.removeEventListener('scroll', debouncedHandleScroll);
            };
        }
    }, [initialized]);

    const loadImages = (size) => {
        setLoading(true);

        fetch(endpoints.MEMES+`?page=${pageRef.current}&size=${size}`)
            .then(response => response.json())
            .then(data => {
                setImages(prevImages => [...prevImages, ...data]);
                setLoading(false);
                if(data.length > 0)
                    pageRef.current = pageRef.current + size;
            })
            .catch(error => {
                console.error('Erro ao buscar imagens:', error);
                setLoading(false);
            });
    };

    const handleScroll = () => {
        let tamanhoDaPagina = document.documentElement.offsetHeight;
        let dezPorcento = tamanhoDaPagina / 10;

        if (window.innerHeight + document.documentElement.scrollTop + dezPorcento >= tamanhoDaPagina) {
            loadImages(1);
        }

    };

    const handleLike = (imageId) => {

        fetch(endpoints.MEMES_LIKE+`/${imageId}`, {
            method: 'POST',
        })
        .then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setImages(images.map(img => img.uuid === imageId ? { ...img, likes: data } : img));
                });
            } else {
                console.error('Erro ao adicionar like:', response.status);
            }
        })
        .catch(error => {
            console.error('Erro ao adicionar like:', error);
        });

    };

    const handleItemClick = (uuid) => {
        navigate(`/ver/${uuid}`);
    };

    return (
        <div className="timeline">
            {images.map(image => (
                <div key={image.id} className="timeline-item" >
                    <div className="image-container">
                        <div className="header">
                            <p className="titulo" onClick={() => handleItemClick(image.uuid)}>{image.titulo}</p>
                            <p className="autor">{image.autor}</p>
                        </div>
                        <img src={image.url} alt={image.alt} />
                    </div>
                    <div className="like-container">
                        <div className="cadastro_data">
                            {format(parseISO(image.cadastro), 'dd/MM/yyyy HH:mm')}
                        </div>
                        <div className="do_like" onClick={() => handleLike(image.uuid)}>
                            <img src={positivo} alt="Like" />
                        </div>
                        <div className="likes">
                            <p>Likes: {image.likes}</p>
                        </div>
                    </div>
                </div>

            ))}
            {loading && <p>Carregando...</p>}
        </div>
    );
};

export default Timeline;
